import * as React from "react";
import BanniereEvenement from "../Mawlid/ui/BanniereEvenementMawlid"
import DetailsEvenements from "../Mawlid/ui/DetailsevenementsMawlid"


function MawlidAnNabi() {
  return (
    <main>
    <BanniereEvenement/>
    <DetailsEvenements/>
    </main>
  );
}

export default MawlidAnNabi;