import React from 'react';
import LayoutGrid from "./ui/layout-grid"
import BandeauDon from '../../components/BandeauDon';


function EntreConnaissance () {


  return (
    <section>
      <LayoutGrid/>
      <BandeauDon/>
    </section>
  );
};

export default EntreConnaissance;
