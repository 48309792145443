import * as React from "react";
import BanniereEvenement from "./ui/BanniereEvenement"
import DetailsEvenements from "./ui/Detailsevenements"



function PageEvenement() {
  return (
    <main>
    <BanniereEvenement/>
    <DetailsEvenements/>
    </main>
  );
}

export default PageEvenement;